import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function AuthForm() {
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleStorage = (token) => {
    try {
      // Clear any existing auth data first
      localStorage.removeItem('authToken');
      
      // Store the new token
      localStorage.setItem('authToken', token);
      
      // Optionally, log the token (for debugging only, avoid in production)
      console.log('Stored token:', token);
      
      // Set up axios default authorization header
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      return true;
    } catch (error) {
      console.error('Error storing token:', error);
      return false;
    }
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
  
    try {
      const response = await axios.post(
        'https://campus-education-node-deploy-9eb11e988890.herokuapp.com/api/auth/login',
        formData,
        {
          headers: { 
            'Content-Type': 'application/json',
          }
        }
      );
  
      if (!response.data?.token) {
        throw new Error('No token received');
      }
  
      const stored = handleStorage(response.data.token);
      if (!stored) {
        throw new Error('Failed to store auth token');
      }
  
      // Navigate to the user profile page
      navigate('/Userprofile'); 
      
      // Refresh the page to reset the state after login
      window.location.reload();
  
    } catch (err) {
      // Handle specific error messages
      const errorMessage = err.response?.data?.message || err.message || 'Login failed';
      setError(errorMessage);
  
      // Optionally, clear password field after failure
      setFormData(prevData => ({
        ...prevData,
        password: ''
      }));
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Welcome Back!</h2>
      {error && <p style={{ ...styles.error, animation: error ? 'shake 0.3s' : 'none' }}>{error}</p>}
      <form style={styles.form} onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          style={styles.input}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
          style={styles.input}
          required
        />
        <button type="submit" style={styles.button} disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
      <div style={styles.footer}>
        <p style={styles.footerText}>
          Don't have an account? <Link to="/RegisterForm" style={styles.link}>Register</Link>
        </p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: '400px',
    margin: '100px auto',
    padding: '30px',
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#ffffff',
    animation: 'fadeIn 0.5s ease',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
    fontFamily: 'Arial, sans-serif',
    fontWeight: 'bold',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: '15px',
    padding: '12px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    outline: 'none',
    transition: 'border-color 0.3s',
  },
  button: {
    padding: '12px',
    fontSize: '16px',
    backgroundColor: '#007BFF',
    color: '#ffffff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginBottom: '15px',
    fontSize: '14px',
    animation: 'shake 0.3s',
  },
  footer: {
    textAlign: 'center',
    marginTop: '20px',
  },
  footerText: {
    color: '#777',
  },
  link: {
    color: '#007BFF',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
};

// CSS Animations
document.head.insertAdjacentHTML(
  'beforeend',
  `<style>
    @keyframes shake {
      0% { transform: translateX(0); }
      25% { transform: translateX(-5px); }
      50% { transform: translateX(5px); }
      75% { transform: translateX(-5px); }
      100% { transform: translateX(0); }
    }
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
  </style>`
);

export default AuthForm;
