import React from 'react'
import ContactUs from '../Components/ContactUs';
import OtherCampusContacts from '../Components/OtherCampusContacts';
import Mapcomp from '../Components/Mapcomp';

const Contact = () => {
  return (
    <div>
      <ContactUs/>
      <OtherCampusContacts/>
      <Mapcomp/>

    </div>
  )
}
export default Contact;