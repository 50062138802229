import React from 'react';
import { useNavigate } from 'react-router-dom';
import tried from '../Assets/tried.jpg';
import whatlogo from '../Assets/whatlogo.png';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleAbout = () => {
    navigate('/About');
  };

  const handleChoosePath = () => {
    navigate('/Query');
  };

  return (
    <div className="landing-page">
      {/* Left Content */}
      <div className="left-content">
        <h1 className="heading">
          "Transform Your College Journey with My Expert Guidance!"
        </h1>
        
        <p className="subheading">
          "Navigate the Confusion and Find the Right Path for Your Future!"
        </p>
        
        <div className="button-group">
          <button className="about-button" onClick={handleAbout}>
            More about us
          </button>
          
          <button className="path-button" onClick={handleChoosePath}>
            Instantly Choose Your Path to Success
          </button>
        </div>
      </div>

      {/* Right Content */}
      <div className="right-content">
        <img 
          src={tried}
          alt="Business Coach"
          className="coach-image"
        />
      </div>

      {/* Chat Bubble - WhatsApp Redirect */}
      <a 
        href="https://wa.me/7827501855?text=Hi%20I%20need%20assistance" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="whatsapp-link"
      >
        <span className="whatsapp-text">
          Hi! What can I do for you?
        </span>
        <div className="whatsapp-avatar">
          <img 
            src={whatlogo} 
            alt="Chat Avatar"
            className="avatar-image"
          />
        </div>
      </a>

      {/* Responsive Styles */}
      <style>
        {`
          .landing-page {
            font-family: system-ui, -apple-system, sans-serif;
            max-width: auto;
            margin: 0 auto;
            padding: 20px;
            display: flex;
            align-items: center;
            min-height: 100vh;
            background-color: #fafafa;
            position: relative;
          }

          .left-content {
            flex: 1;
            padding-right: 40px;
          }

          .heading {
            font-size: 48px;
            font-weight: 700;
            line-height: 1.2;
            margin-bottom: 24px;
            color: #1a1a1a;
          }

          .subheading {
            font-size: 18px;
            color: #666;
            margin-bottom: 32px;
          }

          .button-group {
            display: flex;
            gap: 16px;
          }

          .about-button,
          .path-button {
            padding: 12px 24px;
            font-size: 16px;
            cursor: pointer;
            border-radius: 24px;
            transition: all 0.3s;
          }

          .about-button {
            background-color: #1a1a1a;
            color: white;
            border: none;
          }

          .path-button {
            background-color: transparent;
            color: #1a1a1a;
            border: 1px solid #1a1a1a;
          }

          .right-content {
            flex: 1;
            position: relative;
          }

          .coach-image {
            width: 100%;
            height: auto;
            object-fit: cover;
          }

          .whatsapp-link {
            position: fixed;
            bottom: 24px;
            right: 24px;
            display: flex;
            align-items: center;
            gap: 12px;
            background-color: white;
            padding: 12px 20px;
            border-radius: 24px;
            box-shadow: 0 2px 12px rgba(0,0,0,0.1);
            text-decoration: none;
          }

          .whatsapp-text {
            font-size: 14px;
            color: #666;
          }

          .whatsapp-avatar {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: #ddd;
            overflow: hidden;
          }

          .avatar-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          /* Mobile View */
          @media (max-width: 767px) {
            .landing-page {
              flex-direction: column;
              padding: 15px;
              text-align: center;
            }

            .left-content {
              padding-right: 0;
              text-align: center;
            }

            .heading {
              font-size: 32px;
              margin-bottom: 16px;
            }

            .subheading {
              font-size: 16px;
              margin-bottom: 24px;
            }

            .button-group {
              flex-direction: column;
              gap: 12px;
            }

            .about-button,
            .path-button {
              width: 100%;
              max-width: 250px;
            }

            .right-content {
              margin-top: 16px;
              width: 100%;
            }

            .whatsapp-link {
              bottom: 16px;
              right: 16px;
              padding: 10px 16px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default LandingPage;
