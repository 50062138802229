import React from "react";
import { useNavigate } from 'react-router-dom';
import './Middleabout.css';
import middle1 from '../Assets/middle1.jpg';
import middle2 from '../Assets/middle2.jpg';

function Middleabout() {
  const navigate = useNavigate();

  const handleprogram = () => {
    navigate('/courses'); // Change this to the path you defined for the program details
  };
  return (
    <div className="Middleabout">
      <div className="middle">
        <section className="main">
          <div className="main-left">
            <img src={middle1} alt="middle1" className="image-left" />
          </div>
          <div className="main-right">
            <img src={middle2} alt="middle2" className="image-right" />
          </div>

        </section>
        <header className="header">
          <h1 className="header-title">About Campus Education</h1>
          <p className="header-subtitle">
          At Campus Education, we are dedicated to shaping the academic futures of students by providing comprehensive admission services to universities across India. With a portfolio that spans over 50 prestigious academic partners, we offer a wide range of programs, including undergraduate (UG), postgraduate (PG), diploma, and certificate courses in diverse disciplines.
          </p>
          <p className="header-mission">
          Our core mission is to guide students through the complexities of university admissions, ensuring a seamless transition from initial counseling to securing a place in the university campus of their choice. Our expert counselors offer personalized support tailored to each student's aspirations, helping them make informed decisions about their academic journey.
          </p>
          <div className="buttons">
            <button className="btn" onClick={handleprogram}>University Mission Statement</button>
            <button className="btn" onClick={handleprogram}>University Vision Achievement</button>
          </div>
          <button className="program-btn" onClick={handleprogram}>
        View Our Program →
      </button>
        </header>
      </div>


      <footer className="Middle-footer">
        <div className="Middle-footer-stats">
          <div className="stat-item">
            <h2>90%</h2>
            <p>Post-Graduation Success Rate</p>
          </div>
          <div className="stat-item">
            <h2>Top 10</h2>
            <p>Colleges That Create Futures</p>
          </div>
          <div className="stat-item">
            <h2>No.1</h2>
            <p>In The Nation For Materials R&D</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Middleabout;
