import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Userform.css';

const specializations = [
  'Computer Science & IT',
  'Engineering',
  'Business Management',
  'Medical Sciences',
  'Arts & Design',
  'Law',
  'Architecture',
  'Education',
  'Hospitality Management'
];

const indianStates = [
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
  'Andaman and Nicobar Islands',
  'Chandigarh',
  'Dadra and Nagar Haveli and Daman and Diu',
  'Delhi',
  'Jammu and Kashmir',
  'Ladakh',
  'Lakshadweep',
  'Puducherry'
];

const ProcessStep = ({ number, title, description }) => (
  <div className="process-step">
    <div className="step-number">
      <div className="number-circle">{number}</div>
    </div>
    <div className="step-content">
      <h3 className="step-title">{title}</h3>
      <p className="step-description">{description}</p>
    </div>
  </div>
);

const RegistrationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    formNumber: Math.floor(100000 + Math.random() * 900000),
    name: '',
    gender: '',
    phone: '',
    specialization: '',
    state: '',
    dob: '',
    courseName: ''
  });

  // State for popup message and type
  const [popup, setPopup] = useState({
    visible: false,
    message: '',
    type: '' // 'success' or 'error'
  });

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/AuthForm');
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);

    try {
      const response = await axios.post('https://campus-education-node-deploy-9eb11e988890.herokuapp.com/api/form/apply-form', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      console.log('Response:', response.data);

      // Show success popup
      setPopup({
        visible: true,
        message: 'Form submitted successfully!',
        type: 'success'
      });

      setTimeout(() => {
        setPopup({ visible: false, message: '', type: '' });
        navigate('/Userprofile'); // Navigate to Home on success
      }, 3000);
    } catch (error) {
      console.error('Error submitting form:', error);

      // Show error popup
      setPopup({
        visible: true,
        message: 'Failed to submit form. Please try again.',
        type: 'error'
      });

      setTimeout(() => {
        setPopup({ visible: false, message: '', type: '' });
      }, 3000);
    }
  };


  const steps = [
    {
      title: 'Counseling and Guidance',
      description:
        'Our team of expert counselors assists students in identifying the best universities and courses based on their academic background and career goals.'
    },
    {
      title: 'Application Submission',
      description:
        'We help students with the application process, ensuring all necessary documentation is submitted accurately and on time.'
    },
    {
      title: 'Admission Support',
      description:
        "From securing acceptance to completing formalities, we provide end-to-end support until the student's admission is confirmed."
    },
    {
      title: 'University Campus Enrollment',
      description:
        'Once admitted, students begin their journey by studying at their selected university campus, fully supported by Campus Education throughout the process.'
    }
  ];

  return (
    <div className="registration-container">
      {popup.visible && (
        <div className={`custom-popup ${popup.type} visible`}>
          <p>{popup.message}</p>
        </div>
      )}
      <div className="content-grid">
        <div className="process-section">
          <h2 className="section-title">Our Process</h2>
          {steps.map((step, index) => (
            <ProcessStep
              key={index}
              number={index + 1}
              title={step.title}
              description={step.description}
            />
          ))}
        </div>
        <div className="registration-card">
          <div className="card-header">
            <div className="card-header-content">
              <h2 className="card-title">Student Registration</h2>
            </div>
          </div>
          <div className="card-content">
            <form className="registration-form" onSubmit={handleSubmit}>
              <div className="form-field">
                <label className="form-label">
                  Full Name
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Enter your full name"
                  required
                />
              </div>
              <div className="form-field">
                <label className="form-label">
                  Phone Number
                  <span className="required">*</span>
                </label>
                <input
                  type="number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Phone Number"
                  required
                />
              </div>

              <div className="form-field">
                <label className="form-label">
                  Gender
                  <span className="required">*</span>
                </label>
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="form-select"
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div className="form-field">
                <label className="form-label">
                  Specialization
                  <span className="required">*</span>
                </label>
                <select
                  name="specialization"
                  value={formData.specialization}
                  onChange={handleChange}
                  className="form-select"
                  required
                >
                  <option value="">Select Specialization</option>
                  {specializations.map((spec) => (
                    <option key={spec} value={spec}>
                      {spec}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-field">
                <label className="form-label">
                  Course Name
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="courseName"
                  value={formData.courseName}
                  onChange={handleChange}
                  className="form-input"
                  placeholder="Enter your Course Name"
                  required
                />
              </div>

              <div className="form-field">
                <label className="form-label">
                  State
                  <span className="required">*</span>
                </label>
                <select
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  className="form-select"
                  required
                >
                  <option value="">Select State</option>
                  {indianStates.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-field">
                <label className="form-label">
                  Date of Birth
                  <span className="required">*</span>
                </label>
                <input
                  type="date"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  className="form-input"
                  required
                />
              </div>

              <button type="submit" className="submit-button">
                <span>Submit Application</span>
                <span className="check-icon">✓</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
