import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const styles = {
  container: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#f7f7f7',
  },
  sidebar: {
    width: '250px',
    backgroundColor: '#333',
    position: 'fixed',
    height: '100vh',
    left: 0,
    top: 0,
    borderRight: '1px solid #444',
    color: '#f0f0f0',
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    padding: '20px',
    borderBottom: '1px solid #444',
    color: '#f0f0f0',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  menuContainer: {
    padding: '20px 0',
    flexGrow: 1,
    overflowY: 'auto',
  },
  menuItem: {
    padding: '15px 25px',
    display: 'flex',
    alignItems: 'center',
    color: '#ccc',
    fontSize: '15px',
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'background 0.3s, color 0.3s',
    border: 'none',
    backgroundColor: 'transparent',
    width: '100%',
    textAlign: 'left',
  },
  activeMenuItem: {
    backgroundColor: '#ff5722',
    color: 'white',
    fontWeight: 'bold',
  },
  menuIcon: {
    marginRight: '10px',
    fontSize: '18px',
  },
  mainContent: {
    marginLeft: '250px',
    flexGrow: 1,
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  header: {
    backgroundColor: '#ff5722',
    padding: '20px 30px',
    color: 'white',
  },
  headerTitle: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 'bold',
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    color: '#fff',
    fontSize: '14px',
    marginTop: '5px',
  },
  pageContent: {
    padding: '20px',
    flexGrow: 1,
  },
};

const MENU_ITEMS = [
  { id: 'dashboard', label: 'Dashboard', icon: '🏠' },
  { id: 'students', label: 'ManageStudent', icon: '👥' },
  { id: 'reports', label: 'Reports', icon: '📊' },
  { id: 'results', label: 'Result', icon: '📝' },
  { id: 'courses', label: 'Courses', icon: '📚' },
];
const MenuItem = ({ icon, label, isActive, to }) => (
  <Link
    to={to}
    style={{
      ...styles.menuItem,
      ...(isActive ? styles.activeMenuItem : {}),
    }}
  >
    <span style={styles.menuIcon}>{icon}</span>
    {label}
  </Link>
);
const UserPanel = () => {
  const location = useLocation(); 

  return (
    <div style={styles.container}>
      <aside style={styles.sidebar}>
        <div style={styles.logo}>
          Arogya Institute & Physiotherapy Research Centre
        </div>
        <nav style={styles.menuContainer}>
          {MENU_ITEMS.map((item) => (
            <MenuItem
              key={item.id}
              icon={item.icon}
              label={item.label}
              isActive={location.pathname === `/${item.id}`}
              to={`/${item.id}`} 
            />
          ))}
        </nav>
      </aside>


      <main style={styles.mainContent}>
        <div style={styles.pageContent}>
         
        </div>
      </main>
    </div>
  );
};

export default UserPanel;
