import React from 'react';

const Terms = () => {
  return (
    <div style={styles.containermax}>
      <style>
        {` 
          .terms-title {
            color: #04a5e9;
            font-size: 2.5em;
            margin-bottom: 20px;
            text-align: center;
            transition: transform 0.2s ease-in-out;
          }
          .terms-title:hover {
            transform: scale(1.05);
          }
          .terms-subtitle {
            color: #04a5e9;
            font-size: 1.8em;
            margin-top: 30px;
            margin-bottom: 10px;
            text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
            transition: color 0.2s ease;
          }
          .terms-subtitle:hover {
            color: #0284c7;
          }
          .terms-paragraph {
            line-height: 1.8;
            margin-bottom: 15px;
            padding: 0 10px;
          }
          .terms-list {
            margin: 10px 0 15px 20px;
            padding: 0;
            list-style-type: disc;
          }
          .terms-list li {
            margin-bottom: 10px;
          }
          .glass-effect {
            background: rgba(255, 255, 255, 0.9);
            backdrop-filter: blur(15px);
            border-radius: 15px;
            padding: 30px;
            box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
            margin: 20px auto;
            width: 100%; /* Full width for glass effect */
            max-width: auto; /* Maximum width for content */
            border: 1px solid rgba(255, 255, 255, 0.7);
            transition: all 0.3s ease;
          }
          .glass-effect:hover {
            box-shadow: 0 15px 60px rgba(0, 0, 0, 0.4);
          }
        `}
      </style>
      <div className="glass-effect">
        <h1 className="terms-title">Terms and Conditions</h1>
        <p className="terms-paragraph">Welcome to Campus Education. These Terms and Conditions ("Terms") constitute a legally binding agreement between you and Campus Education regarding your access to and use of the Campus Education website ("Site") and the services we provide, including academic counseling, application guidance, and admissions facilitation at our affiliated partner institutions.</p>

        <h2 className="terms-subtitle">1. Acceptance of Terms</h2>
        <p className="terms-paragraph">By accessing or using the Site or our services, you acknowledge that you have read, understood, and agreed to abide by these Terms in full. If you do not agree to these Terms, you are requested to cease using our Site and services immediately.</p>

        <h2 className="terms-subtitle">2. Scope of Services</h2>
        <p className="terms-paragraph">Campus Education provides educational support services, which include:</p>
        <ul className="terms-list">
          <li>Academic counseling and guidance on various programs and courses</li>
          <li>Support with the application and admissions process</li>
          <li>Facilitation of enrollment at our affiliated partner universities within India</li>
          <li>Information dissemination regarding undergraduate, postgraduate, diploma, and certificate programs</li>
        </ul>
        <p className="terms-paragraph">These services are intended to assist students in making informed decisions; however, they do not constitute a guarantee of admission.</p>

        <h2 className="terms-subtitle">3. Eligibility and Compliance</h2>
        <p className="terms-paragraph">Use of our services is restricted to individuals who meet the eligibility criteria stipulated by each academic program or institution. It is your responsibility to ensure compliance with the admission requirements and regulations of the chosen program or institution.</p>

        <h2 className="terms-subtitle">4. Application and Admission Process</h2>
        <p className="terms-paragraph">Campus Education endeavors to support applicants throughout the admission process, offering guidance and application assistance. However, Campus Education is not responsible for admissions decisions, which rest solely with the respective academic institutions. Admission to any program is subject to the policies and criteria of each partner university.</p>

        <h2 className="terms-subtitle">5. Fees and Payment Terms</h2>
        <p className="terms-paragraph">Some of our services, including but not limited to application processing, may be fee-based. All applicable fees will be communicated transparently in advance, and payment is required before the services are rendered. Unless expressly stated otherwise, fees are non-refundable. All payments must be made in accordance with the payment terms stipulated.</p>

        <h2 className="terms-subtitle">6. Disclaimer of Admission Guarantee</h2>
        <p className="terms-paragraph">While Campus Education provides extensive support and guidance in the admissions process, we do not and cannot guarantee admission to any specific university or academic program. Admissions decisions are made independently by each partner institution in accordance with their own criteria.</p>

        <h2 className="terms-subtitle">7. Third-Party Institutions</h2>
        <p className="terms-paragraph">Campus Education collaborates with a variety of academic institutions as independent partners. We assume no liability or responsibility for the actions, decisions, or policies of these third-party institutions. Each institution’s specific terms, conditions, and policies govern its admission, academic, and administrative practices.</p>

        <h2 className="terms-subtitle">8. Intellectual Property Rights</h2>
        <p className="terms-paragraph">All intellectual property on the Campus Education Site, including but not limited to text, images, graphics, logos, and trademarks, is either owned by or licensed to Campus Education. Unauthorized use, reproduction, or distribution of any content is strictly prohibited and may result in legal action.</p>

        <h2 className="terms-subtitle">9. Limitation of Liability</h2>
        <p className="terms-paragraph">To the fullest extent permitted by law, Campus Education, its affiliates, and its partners shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from or related to the use of our Site or services. This limitation of liability applies to damages resulting from loss of data, unauthorized access, or any financial losses.</p>

        <h2 className="terms-subtitle">10. Disclaimer of Warranties</h2>
        <p className="terms-paragraph">Campus Education makes no representations or warranties of any kind regarding the accuracy, completeness, reliability, or suitability of the information provided on the Site or in the course of our services. Services are provided "as is" and "as available," and you use the Site and services at your own risk.</p>

        <h2 className="terms-subtitle">11. Privacy Policy</h2>
        <p className="terms-paragraph">We are committed to safeguarding your privacy. For details on how we collect, use, and protect your personal information, please refer to our Privacy Policy.</p>

        <h2 className="terms-subtitle">12. Modification of Terms</h2>
        <p className="terms-paragraph">Campus Education reserves the exclusive right to modify, amend, or update these Terms at any time without prior notice. All modifications will be posted on this page. Your continued use of the Site and services after such modifications constitute acceptance of the updated Terms.</p>

        <h2 className="terms-subtitle">13. Governing Law and Jurisdiction</h2>
        <p className="terms-paragraph">These Terms shall be governed by and construed in accordance with the laws of India. Any dispute arising from or relating to these Terms or your use of the Site and services shall be subject to the exclusive jurisdiction of the courts located in [Your City, India].</p>

        <h2 className="terms-subtitle">14. Contact Information</h2>
        <p className="terms-paragraph">For any questions, concerns, or inquiries regarding these Terms, please contact Campus Education at [Contact Information].</p>
      </div>
    </div>
  );
};

// Container styles
const styles = {
  containermax: {
    width: '100%', 
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(to bottom right, #e8f0fe, #f0f0f0)', // Light gradient background
    margin: '0', // Center the container without margin
  },
};

export default Terms;
