import React from 'react';
import TestimonialCard from './TestimonialCard';
import ImageGrid from './ImageGride';


const About = () => {
  return (
    <div className="about-container">
      <style jsx>{`
        .about-container {
          padding: 20px;
          max-width:auto;
          margin: 0 auto;
            background-color: #ffffff;
        }
        h1 {
          text-align: center;
          color: #0040a9; /* Secondary heading color */
          font-size: 2.5em; /* Larger font size */
          margin-bottom: 20px;
        }

        h2 {
          color: #04a5e9; /* Primary subheading color */
          margin-top: 20px;
          font-size: 1.8em; /* Larger subheading size */
        }

        p {
          line-height: 1.6;
          color: #555;
          margin-bottom: 20px;
        }

        .about-image {
          width: 100%;
          height: auto;
          border-radius: 8px;
          margin: 20px 0;
          transition: transform 0.3s ease; /* Image transition */
        }

        .about-image:hover {
          transform: scale(1.02); /* Slight zoom on hover */
        }

        .section {
          margin: 30px 0;
        }

        .team {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
          gap: 20px; /* Spacing between team members */
        }

        .team-member {
          text-align: center;
          background: #ffffff;
          padding: 10px;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease, box-shadow 0.3s ease; /* Card transition */
        }

        .team-member:hover {
          transform: translateY(-5px); /* Lift effect on hover */
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Shadow on hover */
        }

        .team-member img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          border: 2px solid #0040a9; /* Secondary border color for team member images */
          transition: border-color 0.3s ease; /* Border color transition */
        }

        .team-member img:hover {
          border-color: #04a5e9; /* Change border color on hover to primary color */
        }

        .contact-info {
          margin: 20px 0;
          padding: 10px;
          background: #f9f9f9;
          border-radius: 5px;
        }

        .contact-info h2 {
          color: #0040a9; /* Secondary contact section heading color */
        }
      `}</style>

      <h1>About Campus Education</h1>

      <p>
        At Campus Education, we are dedicated to shaping the academic futures of students by providing comprehensive admission services to universities across India. With a portfolio that spans over 50 prestigious academic partners, we offer a wide range of programs, including undergraduate (UG), postgraduate (PG), diploma, and certificate courses in diverse disciplines.
      </p>
      <p>
        Our core mission is to guide students through the complexities of university admissions, ensuring a seamless transition from initial counseling to securing a place in the university campus of their choice. Our expert counselors offer personalized support tailored to each student's aspirations, helping them make informed decisions about their academic journey.
      </p>
      <p>
        With Campus Education, students receive not just admission assistance but a complete pathway to their educational success within top-tier universities in India.
      </p>
       <TestimonialCard/>
      <div className="contact-info">
        <h2>Contact Us</h2>
        <p>If you have any questions or need assistance, feel free to reach out to us!</p>
        <p>Email: Educationoncampus@gmail.com</p>
        <p>Phone: +917827501855</p>
      </div>
    </div>
  );
};

export default About;
