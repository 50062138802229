import React from 'react';

const Mapcomp = () => {
    return (
        <div>
            <style>
                {`
                .map-section {
                    margin: 20px 0;
                    text-align: center;
                    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
                    border-radius: 8px;
                    overflow: hidden;
                    background-color: #fff;
                }

                .map-section h2 {
                    margin-bottom: 10px;
                    color: #333;
                    font-size: 24px;
                    font-weight: bold;
                }

                .map-section iframe {
                    border: none;
                    border-radius: 8px;
                }

                .contact-info {
                    margin-top: 20px;
                    padding: 20px;
                    background-color: #f9f9f9;
                    border-radius: 8px;
                    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
                }

                .contact-info h3 {
                    margin-bottom: 15px;
                    color: #555;
                    font-size: 20px;
                }

                .contact-info p {
                    margin: 5px 0;
                    color: #666;
                }

                .contact-info a {
                    color: #007bff;
                    text-decoration: none;
                    transition: color 0.3s;
                }

                .contact-info a:hover {
                    color: #0056b3;
                    text-decoration: underline;
                }

                @media (max-width: 600px) {
                    .map-section {
                        margin: 10px 0;
                    }

                    .contact-info {
                        padding: 10px;
                    }
                }
                `}
            </style>
            <section className="map-section">
                <h2>Our Location</h2>
                <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4018.9678207672687!2d77.06885787590056!3d28.585325736222014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1bd429209f59%3A0x30780fde2477a7bb!2sAiprc!5e1!3m2!1sen!2sin!4v1728552490439!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </section>
            <section className="contact-info">
                <h3>Contact Us</h3>
                <p><strong>Address:</strong> E - 520, 1st Floor, Near Ramphal Chowk, Palam Extn, Delhi, India</p>
                <p><strong>Phone:</strong>
                📞 +917827501855  📞 +919354416191</p>
                <p><strong>Email:</strong> <a href="mailto: Educationoncampus@gmail.com"> Educationoncampus@gmail.com</a></p>
            </section>
        </div>
    );
};

export default Mapcomp;
