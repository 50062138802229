import React, { useEffect, useState, useMemo } from 'react';
import './Banner.css'; // Assuming you have styles

const Banner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false); // Track if the device is mobile

  // Function to check if the screen size is mobile
  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768); // Mobile if screen width is less than or equal to 768px
  };

  // Hook to update the screen size on resize
  useEffect(() => {
    checkMobile(); // Check on initial render
    window.addEventListener('resize', checkMobile); // Listen for window resize
    return () => window.removeEventListener('resize', checkMobile); // Cleanup listener on unmount
  }, []);

  // Memoized photos array to prevent re-creation on each render
  const photos = useMemo(() => {
    if (isMobile) {
      return [ // Mobile images (set different images for mobile view)
        {
          url: require('../Assets/mobile1.png'),
          title: 'Mobile Image 1',
          description: 'This is the first mobile image description',
        },
        {
          url: require('../Assets/mobile2.png'),
          title: 'Mobile Image 2',
          description: 'This is the second mobile image description',
        },
        {
          url: require('../Assets/mobile3.png'),
          title: 'Mobile Image 3',
          description: 'This is the third mobile image description',
        },
        {
          url: require('../Assets/mobile4.png'),
          title: 'Mobile Image 4',
          description: 'This is the fourth mobile image description',
        },
        {
          url: require('../Assets/mobile5.png'),
          title: 'Mobile Image 5',
          description: 'This is the fifth mobile image description',
        },
      ];
    } else {
      return [ // Desktop images (default for larger screens)
        {
          url: require('../Assets/1.png'),
          title: 'Image 1',
          description: 'This is the first image description',
        },
        {
          url: require('../Assets/2.png'),
          title: 'Image 2',
          description: 'This is the second image description',
        },
        {
          url: require('../Assets/3.png'),
          title: 'Image 3',
          description: 'This is the third image description',
        },
        {
          url: require('../Assets/4.png'),
          title: 'Image 4',
          description: 'This is the fourth image description',
        },
        {
          url: require('../Assets/5.png'),
          title: 'Image 5',
          description: 'This is the fifth image description',
        },
      ];
    }
  }, [isMobile]); // Recalculate photos when screen size changes

  // Auto-scroll functionality
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length); // Loop back to the start when we reach the end
    }, 3000); // Adjust time interval for scrolling

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [photos]); // photos is now stable due to useMemo

  return (
    <div className="banner-container">
      {photos.length > 0 && (
        <div className="banner-item">
          <img
            src={photos[currentIndex].url}
            alt={photos[currentIndex].title}
            className="banner-image"
          />
        </div>
      )}
    </div>
  );
};

export default Banner;
