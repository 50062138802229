import React from 'react';
import image3 from '../Assets/3.jpg'; // Corrected import statements
import image4 from '../Assets/4.jpg'; 
import image5 from '../Assets/5.jpeg'; // Use different images if available
import { useNavigate } from 'react-router-dom';
import './CampusLife.css';

export const CampusLife = () => {
  const navigate = useNavigate();

  const handleCourse = () => {
    navigate('/DisplayPhoto');
  };
  return (
    <div className="CampusLife">
      <div className="campus">
        <h2>Campus Life</h2>
        <p>
          With Campus Education, students receive not just admission assistance but a complete pathway to their educational success within top-tier universities in India.
        </p>
      </div>

      <div className="CampusImage">
        <div>
          <img src={image3} alt="Students enjoying campus life" />
          <p  onClick={handleCourse}>Student Life →</p>
        </div>
        <div>
          <img src={image4} alt="Arts and cultural events" />
          <p  onClick={handleCourse}>Arts & Culture →</p>
        </div>
        <div>
          <img src={image5} alt="Campus recreation and wellness facilities" />
          <p onClick={handleCourse}>Recreation & science →</p>
        </div>
      </div>
    </div>
  );
};
