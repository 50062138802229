import React from 'react';
const styles = {
    containerMaxe: {
        width: '100%',
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        background: 'linear-gradient(to bottom right, #e8f0fe, #f0f0f0)', 
        margin: '0',
    },
    glassEffectsec: {
        background: 'rgba(255, 255, 255, 0.9)',
        backdropFilter: 'blur(15px)',
        borderRadius: '15px',
        padding: '30px',
        boxShadow: '0 10px 40px rgba(0, 0, 0, 0.3)',
        margin: '20px auto',
        width: '100%', 
        maxWidth: 'auto', 
        border: '1px solid rgba(255, 255, 255, 0.7)',
        transition: 'all 0.3s ease',
    },
    glassEffectHover: {
        boxShadow: '0 15px 60px rgba(0, 0, 0, 0.4)',
    },
    termsTitle: {
        color: '#04a5e9',
        fontSize: '2.5em',
        marginBottom: '20px',
        textAlign: 'center',
        transition: 'transform 0.2s ease-in-out',
    },
    termsTitleHover: {
        transform: 'scale(1.05)',
    },
    termsSubtitle: {
        color: '#04a5e9',
        fontSize: '1.8em',
        marginTop: '30px',
        marginBottom: '10px',
        textShadow: '1px 1px 5px rgba(0, 0, 0, 0.2)',
        transition: 'color 0.2s ease',
    },
    termsSubtitleHover: {
        color: '#0284c7',
    },
    termsParagraph: {
        lineHeight: '1.8',
        marginBottom: '15px',
        padding: '0 10px',
    },
    termsList: {
        margin: '10px 0 15px 20px',
        padding: '0',
        listStyleType: 'disc',
    },
    termsListItem: {
        marginBottom: '10px',
    },
};

const PrivacyPolicy = () => {
    return (
        <div style={styles.containerMaxe}>
            <div style={styles.glassEffectsec}>
                <h1 style={styles.termsTitle}>Privacy Policy</h1>
                <p style={styles.termsParagraph}>
                    Campus Education is committed to safeguarding the privacy of individuals who visit our website and use our services. This Privacy Policy outlines how we collect, use, protect, and disclose your personal information. By using our Site and services, you consent to the practices described in this policy.
                </p>

                <h2 style={styles.termsSubtitle}>1. Information We Collect</h2>
                <p style={styles.termsParagraph}>We may collect various types of information to provide you with personalized and efficient services:</p>
                <ul style={styles.termsList}>
                    <li style={styles.termsListItem}><strong>Personal Information:</strong> This includes your name, contact information (such as email address and phone number), educational background, and other relevant details that you voluntarily provide.</li>
                    <li style={styles.termsListItem}><strong>Usage Data:</strong> We may collect information on how you use our Site, including the pages viewed, links clicked, and other browsing actions. This data helps us analyze and improve user experience.</li>
                    <li style={styles.termsListItem}><strong>Device Information:</strong> We may collect information about the device you use to access our Site, including IP address, browser type, operating system, and settings.</li>
                    <li style={styles.termsListItem}><strong>Cookies:</strong> Our Site may use cookies and similar tracking technologies to enhance functionality and gather information. You may set your browser to refuse cookies, but this may affect certain features of the Site.</li>
                </ul>

                <h2 style={styles.termsSubtitle}>2. Use of Information</h2>
                <p style={styles.termsParagraph}>The information we collect is used for the following purposes:</p>
                <ul style={styles.termsList}>
                    <li style={styles.termsListItem}><strong>To Provide Services:</strong> We use your personal information to offer counseling, assist with applications, and facilitate admissions to partner universities.</li>
                    <li style={styles.termsListItem}><strong>Communication:</strong> We may use your contact information to send notifications, updates, and marketing messages related to our services. You may opt out of marketing communications at any time.</li>
                    <li style={styles.termsListItem}><strong>Site Improvement:</strong> Usage data and device information help us monitor and improve the performance, functionality, and security of our Site.</li>
                    <li style={styles.termsListItem}><strong>Compliance and Legal Obligations:</strong> We may use or disclose your information to comply with applicable laws, regulations, or legal processes.</li>
                </ul>

                <h2 style={styles.termsSubtitle}>3. Information Sharing and Disclosure</h2>
                <p style={styles.termsParagraph}>We respect your privacy and only share information under the following conditions:</p>
                <ul style={styles.termsList}>
                    <li style={styles.termsListItem}><strong>With Partner Universities:</strong> We may share your application and academic information with partner institutions for the purpose of admissions and enrollment facilitation.</li>
                    <li style={styles.termsListItem}><strong>Service Providers:</strong> We may employ trusted third-party vendors to assist with Site operations, data storage, and customer support. These service providers have access to your information solely for performing tasks on our behalf.</li>
                    <li style={styles.termsListItem}><strong>Legal Requirements:</strong> We may disclose information if required to comply with legal obligations, protect rights, or respond to requests from law enforcement.</li>
                </ul>

                <h2 style={styles.termsSubtitle}>4. Data Security</h2>
                <p style={styles.termsParagraph}>Campus Education is committed to protecting your personal information. We use industry-standard security measures, including encryption, access controls, and secure servers, to protect against unauthorized access, alteration, or disclosure. However, no data transmission over the internet is entirely secure, and we cannot guarantee the absolute security of your information.</p>

                <h2 style={styles.termsSubtitle}>5. Data Retention</h2>
                <p style={styles.termsParagraph}>We retain personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. When personal information is no longer needed, we take appropriate measures to delete, de-identify, or anonymize it.</p>

                <h2 style={styles.termsSubtitle}>6. Your Rights</h2>
                <p style={styles.termsParagraph}>You have certain rights regarding your personal information:</p>
                <ul style={styles.termsList}>
                    <li style={styles.termsListItem}><strong>Access and Correction:</strong> You may request access to, or correction of, your personal information.</li>
                    <li style={styles.termsListItem}><strong>Deletion:</strong> You may request that we delete your personal information, subject to legal and contractual obligations.</li>
                    <li style={styles.termsListItem}><strong>Opt-Out of Marketing:</strong> You may unsubscribe from marketing communications at any time by following the instructions provided in the message or by contacting us.</li>
                </ul>
                <p style={styles.termsParagraph}>To exercise any of these rights, please contact us at [Contact Information].</p>

                <h2 style={styles.termsSubtitle}>7. Third-Party Links</h2>
                <p style={styles.termsParagraph}>Our Site may contain links to third-party websites. Campus Education is not responsible for the privacy practices, content, or policies of these external websites. We recommend reviewing the privacy policies of any third-party sites you visit.</p>

                <h2 style={styles.termsSubtitle}>8. Children's Privacy</h2>
                <p style={styles.termsParagraph}>Our services are not intended for individuals under the age of 16. We do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will take steps to delete such information.</p>

                <h2 style={styles.termsSubtitle}>9. Changes to this Privacy Policy</h2>
                <p style={styles.termsParagraph}>Campus Education reserves the right to amend or update this Privacy Policy at any time. All changes will be posted on this page, and the "Last Updated" date at the top will be revised. Continued use of our Site after such changes indicates your acceptance of the updated Privacy Policy.</p>

                <h2 style={styles.termsSubtitle}>10. Contact Us</h2>
                <p style={styles.termsParagraph}>If you have any questions or concerns about this Privacy Policy or our data handling practices, please contact us at:</p>
                <p style={styles.termsParagraph}>
                    Campus Education<br />
                    📍 520, Palam Colony,New Delhi, DL-110075, India<br />
                    📧 Educationoncampus@gmail.com<br />
                    📞+917827501855,📞+919354416191
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
