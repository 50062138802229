import React, { useState } from 'react';
import { X, Send, User, Mail, Phone, MessageSquare } from 'lucide-react';
import emailjs from 'emailjs-com';

const PopupForm = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [focusedField, setFocusedField] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send the email
    emailjs.sendForm('service_2z95v23', 'template_6rht9lj', e.target, 'Cjsf3fMtw-NnmJlUx')
      .then((result) => {
        console.log('Email sent:', result.text);
        onClose(); // Close the form after successful submission
      }, (error) => {
        console.log('Error sending email:', error.text);
      });
  };

  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="form-background"></div>
        <div className="header">
          <div className="title-wrapper">
            <h2>Contact Us</h2>
            <p className="subtitle">We'd love to hear from you</p>
          </div>
          <button onClick={onClose} className="close-button">
            <X size={20} />
          </button>
        </div>
        
        <form onSubmit={handleSubmit}>
          <div className={`input-group ${focusedField === 'name' ? 'focused' : ''}`}>
            <User size={20} className="input-icon" />
            <input
              type="text"
              name="name"  // This name is used to capture the value in the email
              value={formData.name}
              onChange={handleChange}
              onFocus={() => setFocusedField('name')}
              onBlur={() => setFocusedField(null)}
              required
              placeholder="Your name"
            />
          </div>
          
          <div className={`input-group ${focusedField === 'email' ? 'focused' : ''}`}>
            <Mail size={20} className="input-icon" />
            <input
              type="email"
              name="email" // This name is used to capture the value in the email
              value={formData.email}
              onChange={handleChange}
              onFocus={() => setFocusedField('email')}
              onBlur={() => setFocusedField(null)}
              required
              placeholder="Your email"
            />
          </div>
          
          <div className={`input-group ${focusedField === 'phone' ? 'focused' : ''}`}>
            <Phone size={20} className="input-icon" />
            <input
              type="tel"
              name="phone" // This name is used to capture the value in the email
              value={formData.phone}
              onChange={handleChange}
              onFocus={() => setFocusedField('phone')}
              onBlur={() => setFocusedField(null)}
              required
              placeholder="Your phone number"
            />
          </div>
          
          <div className={`input-group ${focusedField === 'message' ? 'focused' : ''}`}>
            <MessageSquare size={20} className="input-icon" />
            <textarea
              name="message" // This name is used to capture the value in the email
              value={formData.message}
              onChange={handleChange}
              onFocus={() => setFocusedField('message')}
              onBlur={() => setFocusedField(null)}
              rows="4"
              required
              placeholder="Your message"
            />
          </div>
          
          <div className="button-group">
            <button type="button" onClick={onClose} className="cancel-button">
              Cancel
            </button>
            <button type="submit" className="submit-button">
              Send Message
              <Send size={16} className="ml-2" />
            </button>
          </div>
        </form>
      </div>

      <style jsx>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.2);
          backdrop-filter: blur(10px);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
          animation: fadeIn 0.4s cubic-bezier(0.4, 0, 0.2, 1);
          padding: 20px;
        }

        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        .modal-content {
          position: relative;
          background: rgba(255, 255, 255, 0.9);
          padding: 40px;
          border-radius: 24px;
          box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08),
                      0 0 0 1px rgba(0, 0, 0, 0.05);
          backdrop-filter: blur(10px);
          width: 450px;
          max-width: auto;
          animation: slideUp 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
        }

        @keyframes slideUp {
          from { 
            transform: translateY(20px); 
            opacity: 0; 
          }
          to { 
            transform: translateY(0); 
            opacity: 1; 
          }
        }

        .header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 32px;
        }

        .title-wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        h2 {
          margin: 0;
          font-size: 32px;
          font-weight: 700;
          color: #1a1a1a;
          line-height: 1.2;
        }

        .subtitle {
          margin: 0;
          color: #666;
          font-size: 16px;
        }

        .close-button {
          background: #f5f5f5;
          border: none;
          color: #666;
          cursor: pointer;
          padding: 8px;
          border-radius: 12px;
          transition: all 0.3s;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .close-button:hover {
          background: #eeeeee;
          transform: rotate(90deg);
        }

        .input-group {
          position: relative;
          margin-bottom: 20px;
        }

        .input-group.focused {
          transform: translateY(-1px);
        }

        .input-icon {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          color: #999;
          transition: all 0.3s;
        }

        .input-group.focused .input-icon {
          color: #2563eb;
        }

        textarea ~ .input-icon {
          top: 24px;
          transform: none;
        }

        input, textarea {
          width: 80%;
          padding: 16px 16px 16px 50px;
          background: #f8f9fc;
          border: 1px solid #e5e7eb;
          border-radius: 12px;
          color: #1a1a1a;
          font-size: 16px;
          transition: all 0.3s;
        }

        textarea {
          resize: none;
          min-height: 140px;
        }

        input::placeholder, textarea::placeholder {
          color: #999;
        }

        .input-group.focused input,
        .input-group.focused textarea {
          border-color: #2563eb;
          background: #fff;
          box-shadow: 0 0 0 4px rgba(37, 99, 235, 0.1);
        }

        .button-group {
          display: flex;
          gap: 12px;
          margin-top: 32px;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 14px 24px;
          border-radius: 12px;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.3s;
          border: none;
        }

        .cancel-button {
          background: #f5f5f5;
          color: #666;
          flex: 1;
        }

        .cancel-button:hover {
          background: #eeeeee;
          transform: translateY(-1px);
        }

        .submit-button {
          background: #2563eb;
          color: white;
          flex: 2;
        }

        .submit-button:hover {
          background: #1d4ed8;
          transform: translateY(-1px);
          box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
        }

        .ml-2 {
          margin-left: 8px;
        }

        @media (max-width: 768px) {
          .modal-content {
            width: 90%;
            padding: 24px;
          }
          
          h2 {
            font-size: 28px;
          }

          .subtitle {
            font-size: 14px;
          }

          .button-group {
            flex-direction: column;
          }

          .submit-button, .cancel-button {
            width: 100%;
          }
        }

        @media (max-width: 480px) {
          .modal-content {
            padding: 20px;
          }

          h2 {
            font-size: 24px;
          }

          .subtitle {
            font-size: 13px;
          }

          input, textarea {
            padding: 12px 12px 12px 40px;
            font-size: 14px;
          }

          .input-icon {
            left: 12px;
          }

          .submit-button, .cancel-button {
            padding: 12px;
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
};

export default PopupForm;
