import React, { useState } from 'react';
import stunew from '../Assets/stunew.jpg';
import imnew from '../Assets/imnew.jpg';
import middle1 from '../Assets/middle1.jpg';
import './OtherCampusContacts.css';

const OtherCampusContacts = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create mailto link with the correct format
    const mailtoLink = `mailto:Educationoncampus@gmail.com?subject=Contact Form Submission from ${encodeURIComponent(name)}&body=Name: ${encodeURIComponent(name)}%0AEmail: ${encodeURIComponent(email)}%0AMessage: ${encodeURIComponent(message)}`;

    // Redirect to the mailto link
    window.location.href = mailtoLink;

    // Clear form fields
    setName('');
    setEmail('');
    setMessage('');
};

  return (
    <div className="contact">
      <div className="imageContainer">
        <div className="imageCard">
          <img src={stunew} alt={"Nature"} className="image" />
          <div className="textBlock">
            <h4>"Success is the sum of small efforts, repeated day in and day out." </h4>
            <p>– Robert Collier</p>
          </div>
        </div>
        <div className="imageCard">
          <img src={imnew} alt={"Nature"} className="image" />
          <div className="textBlock">
            <h4>"The purpose of our lives is to be happy."</h4>
            <p> – Dalai Lama</p>
          </div>
        </div>
        <div className="imageCard">
          <img src={middle1} alt={"Nature"} className="image" />
          <div className="textBlock">
            <h4>"Success is walking from failure to failure with no loss of enthusiasm." </h4>
            <p>– Winston Churchill</p>
          </div>
        </div>
      </div>

      <div className="contact-form-container">
        <h1 className="form-title">Get in Touch</h1>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              className="form-textarea"
            ></textarea>
          </div>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default OtherCampusContacts;
