import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Footer.css'; // Import the CSS file for styling
import logo from '../Assets/logo.jpg';
import SocialMediaBar from './Socialmedia';

export const Footer = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setError(''); // Clear error on input change
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
    } else {
      console.log('Email submitted:', email);
      setEmail(''); // Clear input after submission
    }
  };

  return (
    <footer className="footer">
      <div className="newsletter-section">
        <h3>Subscribe to our Newsletter</h3>
        <form className="newsletter-form" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter Your Email"
            aria-label="Email Address"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <button type="submit" aria-label="Submit Email">
            Submit <span>&#10148;</span>
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>

      <div className="footer-content">
        <div className="footer-branding">
          <img src={logo} alt="Campus Logo" className="footer-logo" />
          <p>
            We are passionate about education, dedicated to providing high-quality resources for learners from all backgrounds.
          </p>
          <p>
            📍 <Link to="#location" aria-label="Campus Location">520, Palam Colony, New Delhi, DL-110075, India</Link>
          </p>
          <p>
            📞 <a href="tel:+917827501855">+917827501855</a>
          </p>
          <p>
            📞 <a href="tel:+919354416191">+919354416191</a>
          </p>
          <p>
            📧 <a href="mailto:Educationoncampus@gmail.com">Educationoncampus@gmail.com</a>
          </p>
        </div>

        <div className="footer-links">
          <nav>
            <div>
              <h4>Our Campus</h4>
              <ul>
                <li><Link to="Courses">Academic</Link></li>
                <li><Link to="DisplayPhoto">Athletics</Link></li>
                <li><Link to="DisplayPhoto">Campus Life</Link></li>
                <li><Link to="DisplayPhoto">Research</Link></li>
                <li><Link to="/DisplayPhoto">Academic Area</Link></li>
              </ul>
            </div>
          </nav>
          <nav>
            <div>
              <h4>Quick Links</h4>
              <ul>
                <li><Link to="/PrivacyPolicy">Privacy Policy</Link></li>
                <li><Link to="/Terms">Terms of Service</Link></li>
                <li><Link to="/About">FAQs</Link></li>
                <li><Link to="/Contact">Contact Us</Link></li>
              </ul>
            </div>
          </nav>
        </div>

        <div className="recent-updates">
          <h4>Recent Updates</h4>
          <ul>
            <li><Link to="/DisplayPhoto">New Course Offerings</Link></li>
            <li><Link to="/DisplayPhoto">Research Grants Available</Link></li>
            <li><Link to="/DisplayPhoto">Campus Renovations Completed</Link></li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <SocialMediaBar />
        <p>Copyright © 2024. All Rights Reserved by <Link to="#campus-education" aria-label="Campus Education">Campus Education</Link></p>
      </div>
    </footer>
  );
};
