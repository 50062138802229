import React, { useState } from 'react';

const videoData = []; // Empty array to simulate no data found

function DisplayVideos() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');

  const openModal = (videoUrl) => {
    setCurrentVideo(videoUrl);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentVideo('');
  };

  return (
    <div className="DisplayVideos">
      <h1>Campus Education Videos</h1>
      <div className="video-gallery">
        {videoData.length > 0 ? (
          videoData.map(video => (
            <div key={video.id} className="video-item">
              <img
                src={video.thumbnailUrl}
                alt={video.title}
                className="thumbnail"
                onClick={() => openModal(video.videoUrl)}
              />
              <h2>{video.title}</h2>
              <p>Duration: {video.duration}</p>
              <p>Views: {video.views}</p>
              <p>Author: {video.author}</p>
            </div>
          ))
        ) : (
          <div className="no-data">No Data Found</div>
        )}
      </div>

      {isOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <span className="close" onClick={closeModal}>&times;</span>
            <video controls autoPlay>
              <source src={currentVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}

      <style jsx>{`
        .DisplayVideos {
          text-align: center; /* Center align text */
        }

        .video-gallery {
          display: flex;
          flex-wrap: wrap;
          justify-content: center; /* Center items in the flex container */
        }

        .video-item {
          margin: 10px;
          text-align: left;
        }

        .thumbnail {
          cursor: pointer; /* Change cursor to pointer on hover */
          width: 200px; /* Set thumbnail width */
          height: auto; /* Maintain aspect ratio */
        }

        .no-data {
          margin: 20px;
          font-size: 24px; /* Make the text larger */
          color: #666; /* Change the color to a softer shade */
        }

        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .modal-content {
          background: white;
          padding: 20px;
          border-radius: 5px;
          position: relative;
        }

        .close {
          position: absolute;
          top: 10px;
          right: 20px;
          cursor: pointer;
          font-size: 24px;
        }

        video {
          max-width: 100%; /* Make video responsive */
        }
      `}</style>
    </div>
  );
}

export default DisplayVideos;
