import React from 'react';

import stunew from '../Assets/stunew.jpg';
import './ContactUs.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const ContactUs = () => {
  return (
    <div className="contactUs">
      <div className="contact-content">
        <h1>Contact</h1>
        <p>Home &gt; Contact</p>
      </div>
      <div className="grad-cap">
        <img src={stunew} alt="Graduation Hat" />
      </div>
      <p><strong>Welcome to Campus Education</strong></p>
      <section className="contact-info">
        <h2>General Contact Information</h2>
        
        <div className="contact-details">
          
          {/* Address */}
          <div className="address">
            <i className="icon-map"></i>
            <p>520, Palam Colony, New Delhi, DL-110075, India</p>
          </div>

          {/* Phone Numbers */}
          <div className="phone">
            <i className="icon-phone"></i>
            <p>+44 20 4154 2541</p>
            <p>+44 20 4154 2542</p>
          </div>

          {/* Business Hours */}
          <div className="hours">
            <i className="icon-clock"></i>
            <p>Mon-Fri: 10 AM – 6 PM</p>
            <p>Saturday: 10 AM – 4 PM</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
