import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Header from './Components/Header';
import Home from './routes/Home';
import About from './Components/About';
import DisplayPhoto from './Components/DisplayPhoto';
import Contact from './routes/Contact';
import DisplayVideos from './Components/DisplayVideos';
import Courses from './Components/Courses';
import { Footer } from './Components/Footer';
import UniversityList from './Components/UniversityList';
import AuthForm from './Components/AuthForm';
import RegisterForm from './Components/RegisterForm';
import { AuthProvider } from './Context/AuthContext';
import UserForm from './Components/Userform';
import UserProfile from './Components/Userprofile';
import Query from './Components/Query';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Terms from './Components/Terms';
import Dashboard from './Components/Dashboard';
import Reports from './Components/Reports';
import ManageStudent from './Components/ManageStudent';
import Results from './Components/Results';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null; // This component doesn't need to render anything
}

function App() {
  // Check if the token exists in localStorage
  const token = localStorage.getItem('authToken');

  return (
    <AuthProvider>
      <Router>
        <div>
          <Header />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/Userform" element={<UserForm />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/UniversityList" element={<UniversityList />} />
            <Route path="/Query" element={<Query />} />
            <Route path="/Courses" element={<Courses />} />
            <Route path="/RegisterForm" element={<RegisterForm />} />
            <Route path="/AuthForm" element={<AuthForm />} />
            <Route path="/Terms" element={<Terms />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />

            {/* Redirect to UserProfile if token exists, else redirect to AuthForm */}
            <Route 
              path="/Userprofile" 
              element={token ? <UserProfile /> : <Navigate to="/AuthForm" />} 
            />

            <Route path="/DisplayPhoto" element={<DisplayPhoto />} />
            <Route path="/DisplayVideos" element={<DisplayVideos />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/students" element={<ManageStudent />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/results" element={<Results />} />       
          </Routes>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
