import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory

function ProfilePage() {
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [profilePic, setProfilePic] = useState(null);
  const [preview, setPreview] = useState('https://via.placeholder.com/150');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate(); // Hook to access the browser's history

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    console.log('Token found:', token);

    const fetchProfile = async () => {
      try {
        const response = await axios.get('https://campus-education-node-deploy-9eb11e988890.herokuapp.com/api/auth/profile', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        
        console.log(response.data);
        // Adjust to access `user` object within the data
        const userData = response.data.user;
        setProfile({
          name: userData.name,
          email: userData.email,
          phone: userData.phone,
        });
        
        if (userData.profilePic) {
          setPreview(userData.profilePic);
        }
      } catch (error) {
        setMessage('Failed to fetch profile.');
      }
    };

    fetchProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfilePic(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    setMessage('');

    const token = localStorage.getItem('authToken');
    const formData = new FormData();
    formData.append('name', profile.name);
    formData.append('email', profile.email);
    formData.append('phone', profile.phone);
    if (profilePic) {
      formData.append('profilePic', profilePic);
    }

    try {
      const response = await axios.put('https://campus-education-node-deploy-9eb11e988890.herokuapp.com/api/auth/update', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setMessage('Profile updated successfully!');
      } else {
        setMessage('Failed to update profile.');
      }
    } catch (error) {
      setMessage('An error occurred while updating profile.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    // Remove the auth token from localStorage
    localStorage.removeItem('authToken');
    // Redirect to login page after logout
    navigate('/AuthForm'); // Updated from history.push to navigate
    // Reload the page to clear session and force a fresh state
    window.location.reload();
  };

  return (
    <div style={styles.container}>
      <div style={styles.profileContainer}>
        <h2 style={styles.title}>Profile Settings</h2>
        <div 
          style={styles.profilePicContainer}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <img src={preview} alt="Profile" style={styles.profilePic} />
          <label 
            htmlFor="fileInput" 
            style={{
              ...styles.uploadLabel,
              opacity: isHovering ? 1 : 0,
            }}
          >
            Upload Photo
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={styles.fileInput}
            />
          </label>
        </div>
        <div style={styles.inputContainer}>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={profile.name}
            onChange={handleChange}
            style={styles.input}
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={profile.email}
            onChange={handleChange}
            style={styles.input}
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone Number"
            value={profile.phone}
            onChange={handleChange}
            style={styles.input}
          />
          <button 
            onClick={handleSave} 
            style={{
              ...styles.saveButton,
              opacity: loading ? 0.7 : 1,
            }} 
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save Profile'}
          </button>
          {message && <p style={{
            ...styles.message,
            color: message.includes('success') ? '#4CAF50' : '#f44336'
          }}>{message}</p>}
        </div>
        <button 
          onClick={handleLogout} 
          style={styles.logoutButton}
        >
          Logout
        </button>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
    padding: '20px',
  },
  profileContainer: {
    background: 'linear-gradient(to bottom, #ffffff 0%, #f8f9fa 100%)',
    padding: '40px',
    borderRadius: '20px',
    boxShadow: '0 20px 40px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '450px',
    width: '100%',
  },
  title: {
    fontSize: '28px',
    color: '#2c3e50',
    marginBottom: '30px',
    fontWeight: '600',
  },
  profilePicContainer: {
    position: 'relative',
    display: 'inline-block',
    marginBottom: '25px',
  },
  profilePic: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    border: '5px solid transparent',
    background: 'linear-gradient(white, white) padding-box, linear-gradient(45deg, #00c6fb 0%, #005bea 100%) border-box',
    marginBottom: '15px',
    objectFit: 'cover',
  },
  uploadLabel: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'linear-gradient(45deg, rgba(0, 198, 251, 0.9) 0%, rgba(0, 91, 234, 0.9) 100%)',
    color: '#fff',
    padding: '10px 15px',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  fileInput: {
    display: 'none',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginTop: '20px',
  },
  input: {
    padding: '15px',
    fontSize: '16px',
    borderRadius: '12px',
    border: '2px solid #e1e8ed',
    outline: 'none',
  },
  saveButton: {
    padding: '15px',
    fontSize: '16px',
    fontWeight: '600',
    borderRadius: '12px',
    border: 'none',
    background: 'linear-gradient(45deg, #00c6fb 0%, #005bea 100%)',
    color: '#fff',
    cursor: 'pointer',
    marginTop: '10px',
  },
  message: {
    marginTop: '15px',
    fontSize: '14px',
    fontWeight: '500',
  },
  logoutButton: {
    padding: '15px 30px', // Larger padding for a more clickable area
    fontSize: '16px',
    fontWeight: '600',
    borderRadius: '12px',
    border: 'none',
    backgroundColor: '#FF4D4D', // Strong, noticeable red
    color: '#fff',
    cursor: 'pointer',
    marginTop: '20px',
    transition: 'all 0.3s ease',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Soft shadow
  },
  logoutButtonHover: {
    backgroundColor: '#ff1a1a', // Darker red for hover effect
    transform: 'scale(1.05)', // Slightly enlarge on hover
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // Larger shadow on hover
  },
  logoutButtonActive: {
    backgroundColor: '#e60000', // Even darker red for active state
    transform: 'scale(1)', // Return to normal size
  },
};

export default ProfilePage;
