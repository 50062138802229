import React from 'react';
import './UniversityList.css'; // Import the CSS file

const argi = require('../Assets/argi.jpg');
const capital = require('../Assets/capital.jpg');
const daya = require('../Assets/daya.jpg');
const glocal = require('../Assets/glocal.jpg');
const kalinga = require('../Assets/kalinga.jpg');
const mangal = require('../Assets/mangal.jpg');
const monad = require('../Assets/monad.jpg');
const niilm = require('../Assets/niilm.jpg');
const sarvepalli = require('../Assets/sarvepalli.jpg'); 
const shri = require('../Assets/shri.jpg');
const sikkim = require('../Assets/sikkim.jpg');
const sunrise = require('../Assets/sunrise.jpg');
const north = require('../Assets/north.jpg');
const subh = require('../Assets/subh.jpg');

const universitiesData = {
  universities: [
    { id: 1, name: "NIILM University - Kaithal, Haryana", image: niilm },
    { id: 2, name: "Shri Venkateshwara University - Gajraula, Uttar Pradesh", image: shri },
    { id: 3, name: "Sunrise University - Alwar, Rajasthan", image: sunrise },
    { id: 4, name: "Capital University - Jhumri Telaiya, Jharkhand", image: capital },
    { id: 5, name: "Subharti University - Meerut, Uttar Pradesh", image: subh },
    { id: 6, name: "Sarvepalli Radhakrishnan University - Bhopal, Madhya Pradesh", image: sarvepalli },
    { id: 7, name: "Pt. Deendayal Upadhyaya National Institute - Delhi", image: daya },
    { id: 8, name: "Mangalayatan University - Beswan, Uttar Pradesh", image: mangal },
    { id: 9, name: "Glocal University - Saharanpur, Uttar Pradesh", image: glocal },
    { id: 10, name: "North East Frontier Technical University - Along", image: north },
    { id: 11, name: "Monad University - Kastala Kasmabad, Uttar Pradesh", image: monad },
    { id: 12, name: "Arni University - Kangra, Himachal Pradesh", image: argi },
    { id: 13, name: "Sikkim Manipal University - Gangtok, Sikkim", image: sikkim },
    { id: 14, name: "Kalinga University - Raipur, Chhattisgarh", image: kalinga },
  ],
};

const UniversityList = () => {
  return (
    <div className="uni-list-container">
      <div>
      <h1 className="uni-list-title">50+ Top Universities</h1>
      <p className="uni-list-description">
        We have established partnerships with over 50 universities, creating a robust network that enhances academic collaboration and exchange. These partnerships facilitate access to a diverse range of resources, research opportunities, and expert faculty. Students benefit from international experiences and cross-cultural learning, broadening their educational horizons. Additionally, faculty members engage in collaborative research projects and exchange programs, fostering innovation and shared knowledge. This extensive network positions us as a leader in global education, committed to enriching the academic experience for all stakeholders involved.
      </p>
      </div>

      <div className="uni-list-grid">
        {universitiesData.universities.map((university) => (
          <div key={university.id} className="uni-list-card">
            <div className="uni-list-image-container">
              <img src={university.image} alt={university.name} className="uni-list-image" />
            </div>
            <h2 className="uni-list-card-title">{university.name}</h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UniversityList;
