import React, { useState, useEffect } from 'react';
import Banner from '../Components/Banner';
import AcademicsPrograms from '../Components/AcademicsPrograms';
import Middleabout from '../Components/Middleabout';
import { CampusLife } from '../Components/CampusLife';
import { Feedback } from '../Components/Feedback';
import Carousel from '../Components/Carousel';
import PopupForm from '../Components/PopupForm'; 
import Queryengine from '../Components/Queryengine';
import Dashboard from '../Components/Dashboard';
const Home = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {

    const timer = setTimeout(() => {
      setModalOpen(true);
    }, 10000); 
    return () => clearTimeout(timer);
  }, []);

  const closeModal = () => {
    setModalOpen(false); 
  };

  return (
    <div>
      <Banner />
      <Carousel />
      <Queryengine />
      <Middleabout />
      <AcademicsPrograms />
      <CampusLife />
      <Feedback />
      <PopupForm isOpen={isModalOpen} onClose={closeModal} />
      <Carousel />
    </div>
  );
};

export default Home;
