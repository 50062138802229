import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

const SocialMediaBar = () => {
  const iconStyles = {
    color: '#fff',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    textDecoration: 'none',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
    transition: 'transform 0.2s, box-shadow 0.2s',
  };

  const textStyle = {
    color: '#333',
    fontSize: '12px',
    textAlign: 'center',
    marginTop: '5px',
    fontFamily: 'Arial, sans-serif',
    transition: 'color 0.2s, transform 0.2s',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)', // Shadow for depth
  };

  const handleMouseEnter = (e) => {
    const icon = e.currentTarget; // The icon being hovered
    const text = icon.nextElementSibling; // Get the text element
    if (text) {
      icon.style.transform = 'scale(1.1)';
      icon.style.boxShadow = '0 6px 8px rgba(0, 0, 0, 0.2)';
      text.style.color = '#007bff'; // Change text color on hover
      text.style.transform = 'translateY(-2px)'; // Slight upward movement
    }
  };

  const handleMouseLeave = (e) => {
    const icon = e.currentTarget; // The icon being hovered
    const text = icon.nextElementSibling; // Get the text element
    if (text) {
      icon.style.transform = 'scale(1)';
      icon.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
      text.style.color = '#333'; // Reset text color
      text.style.transform = 'translateY(0)'; // Reset text position
    }
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h2 style={{ marginBottom: '15px', fontFamily: 'Arial, sans-serif' }}>Follow Us On</h2>
      <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ ...iconStyles, backgroundColor: '#1877f2' }} // Facebook blue
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <i className="fab fa-facebook-f" style={{ fontSize: '18px' }}></i>
          </a>
          <span style={textStyle}>Facebook</span>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ ...iconStyles, backgroundColor: '#1da1f2' }} // Twitter blue
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <i className="fab fa-twitter" style={{ fontSize: '18px' }}></i>
          </a>
          <span style={textStyle}>Twitter</span>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              ...iconStyles,
              background: 'radial-gradient(circle at 30% 30%, #fdf497, #fdf497, #fd5949, #d6249f, #3f729b)',
            }} // Updated gradient for Instagram
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <i className="fab fa-instagram" style={{ fontSize: '18px' }}></i>
          </a>
          <span style={textStyle}>Instagram</span>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <a
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ ...iconStyles, backgroundColor: '#0077b5' }} // LinkedIn blue
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <i className="fab fa-linkedin-in" style={{ fontSize: '18px' }}></i>
          </a>
          <span style={textStyle}>LinkedIn</span>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaBar;
