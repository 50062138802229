import React, { useEffect, useRef, useState } from 'react';
import './Carousel.css';
import sunrise from '../Assets/sunrise.jpg';
import niilm from '../Assets/niilm.jpg';
import shri from '../Assets/shri.jpg';
import argi from '../Assets/argi.jpg';
import capital from '../Assets/capital.jpg';
import daya from '../Assets/daya.jpg';
import kalinga from '../Assets/kalinga.jpg';
import monad from '../Assets/monad.jpg';
import mangal from '../Assets/mangal.jpg';
import neftu from '../Assets/neftu.jpg';
import sikkim from '../Assets/sikkim.jpg';
import sarvepalli from '../Assets/sarvepalli.jpg';

const partnerLogos = [
  { id: 1, source:niilm  },
  { id: 2, source: sunrise },
  { id: 3, source: shri },
  { id: 4, source: argi },
  { id: 5, source: capital },
  { id: 6, source: daya },
  { id: 7, source: kalinga },
  { id: 8, source: monad },
  { id: 9, source: mangal },
  { id: 10, source: neftu },
  { id: 11, source: niilm },
  { id: 12, source: sikkim },
  { id: 13, source: sarvepalli },
  { id: 14, source: sunrise },
];
const  Carousel = () => {
  const [logoSets, setLogoSets] = useState(2);
  const containerRef = useRef(null);

  useEffect(() => {
    const updateLogoSets = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const logoWidth = 220; // 200px width + 20px margin
        const visibleLogos = Math.ceil(containerWidth / logoWidth);
        const setsNeeded = Math.ceil(visibleLogos / partnerLogos.length) + 1;
        setLogoSets(setsNeeded);
      }
    };

    updateLogoSets();
    window.addEventListener('resize', updateLogoSets);
    return () => window.removeEventListener('resize', updateLogoSets);
  }, []);

  return (
    <div className="partner-container">
      <div className="text-container">
        <h2 className="title">PARTNER</h2>
        <h3 className="subtitle">Academic Partners</h3>
      </div>
      <div className="logo-wrapper" ref={containerRef}>
        <div className="logo-container">
          {[...Array(logoSets)].flatMap(() => 
            partnerLogos.map((logo) => (
              <img 
                key={`${logo.id}-${Math.random()}`}
                src={logo.source} 
                alt={`Partner logo ${logo.id}`}
                className="logo"
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};



export default Carousel;