import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CourseFilter = () => {
  const [universities, setUniversities] = useState([]);
  const [filteredUniversities, setFilteredUniversities] = useState([]);
  
  const [tuitionFee, setTuitionFee] = useState([0, 50000]); // range [min, max]
  const [specialization, setSpecialization] = useState('');
  const [courseType, setCourseType] = useState('');
  const [eligibility, setEligibility] = useState('');
  const [duration, setDuration] = useState('');

  // Fetch data from API
  useEffect(() => {
    axios.get('https://campus-education-node-deploy-9eb11e988890.herokuapp.com/api/university/universities')
      .then((response) => {
        setUniversities(response.data);
        setFilteredUniversities(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Filter universities based on user input
  useEffect(() => {
    const filterResults = universities.filter((university) => {
      return (
        (tuitionFee ? university.Courses.some(course => course.TuitionFeePerYear >= tuitionFee[0] && course.TuitionFeePerYear <= tuitionFee[1]) : true) &&
        (specialization ? university.Courses.some(course => course.Specializations.includes(specialization)) : true) &&
        (courseType ? university.Courses.some(course => course.CourseType === courseType) : true) &&
        (eligibility ? university.Courses.some(course => course.Eligibility === eligibility) : true) &&
        (duration ? university.Courses.some(course => course.Duration === duration) : true)
      );
    });

    setFilteredUniversities(filterResults);
  }, [tuitionFee, specialization, courseType, eligibility, duration, universities]);

  // Define styles as const objects
  const styles = {
    container: {
      width: '80%',
      margin: '0 auto',
      padding: '20px',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
    },
    heading: {
      textAlign: 'center',
      fontSize: '2em',
      color: '#333',
    },
    filterItem: {
      marginBottom: '20px',
    },
    label: {
      fontWeight: 'bold',
      display: 'block',
      marginBottom: '5px',
    },
    inputRange: {
      width: '100%',
      marginBottom: '5px',
    },
    select: {
      width: '100%',
      padding: '8px',
      fontSize: '1em',
      borderRadius: '4px',
      border: '1px solid #ddd',
    },
    feeRange: {
      fontSize: '1.1em',
      marginTop: '5px',
    },
    courseItem: {
      marginTop: '15px',
      padding: '10px',
      backgroundColor: '#fff',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      marginBottom: '10px',
    },
    noResults: {
      textAlign: 'center',
      fontSize: '1.2em',
      color: '#555',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>University Course Finder</h1>

      {/* Tuition Fee */}
      <div style={styles.filterItem}>
        <label style={styles.label}>What is your desired tuition fee per year range?</label>
        <input
          type="range"
          min="0"
          max="100000"
          value={tuitionFee[0]}
          onChange={(e) => setTuitionFee([+e.target.value, tuitionFee[1]])}
          style={styles.inputRange}
        />
        <input
          type="range"
          min="0"
          max="100000"
          value={tuitionFee[1]}
          onChange={(e) => setTuitionFee([tuitionFee[0], +e.target.value])}
          style={styles.inputRange}
        />
        <div style={styles.feeRange}>
          <span>₹{tuitionFee[0]}</span> - <span>₹{tuitionFee[1]}</span>
        </div>
      </div>

      {/* Specialization */}
      {courseType && (
        <div style={styles.filterItem}>
          <label style={styles.label}>Which course specialization are you interested in?</label>
          <select onChange={(e) => setSpecialization(e.target.value)} value={specialization} style={styles.select}>
            <option value="">Select Specialization</option>
            {universities.flatMap((university) =>
              university.Courses.filter(course => course.CourseType === courseType)
                .flatMap(course => course.Specializations)
            ).filter((value, index, self) => self.indexOf(value) === index).map((spec) => (
              <option key={spec} value={spec}>{spec}</option>
            ))}
          </select>
        </div>
      )}

      {/* Course Type */}
      <div style={styles.filterItem}>
        <label style={styles.label}>What course type are you looking for?</label>
        <select onChange={(e) => setCourseType(e.target.value)} value={courseType} style={styles.select}>
          <option value="">Select Course Type</option>
          {universities.flatMap((university) =>
            university.Courses.map((course) => course.CourseType)
          ).filter((value, index, self) => self.indexOf(value) === index).map((courseType) => (
            <option key={courseType} value={courseType}>{courseType}</option>
          ))}
        </select>
      </div>

      {/* Eligibility */}
      <div style={styles.filterItem}>
        <label style={styles.label}>What is your eligibility?</label>
        <select onChange={(e) => setEligibility(e.target.value)} value={eligibility} style={styles.select}>
          <option value="">Select Eligibility</option>
          {universities.flatMap((university) =>
            university.Courses.map((course) => course.Eligibility)
          ).filter((value, index, self) => self.indexOf(value) === index).map((eligibility) => (
            <option key={eligibility} value={eligibility}>{eligibility}</option>
          ))}
        </select>
      </div>

      {/* Duration */}
      <div style={styles.filterItem}>
        <label style={styles.label}>What is your preferred course duration?</label>
        <select onChange={(e) => setDuration(e.target.value)} value={duration} style={styles.select}>
          <option value="">Select Duration</option>
          {universities.flatMap((university) =>
            university.Courses.map((course) => course.Duration)
          ).filter((value, index, self) => self.indexOf(value) === index).map((duration) => (
            <option key={duration} value={duration}>{duration}</option>
          ))}
        </select>
      </div>

      {/* Filtered Results */}
      <h2 style={styles.heading}>Filtered Universities</h2>
      <div>
        {filteredUniversities.length === 0 ? (
          <p style={styles.noResults}>No results found</p>
        ) : (
          filteredUniversities.map((university) => (
            <div key={university._id}>
              <h3>{university.UniversityName}</h3>
              {university.Courses.filter((course) =>
                (tuitionFee ? course.TuitionFeePerYear >= tuitionFee[0] && course.TuitionFeePerYear <= tuitionFee[1] : true) &&
                (specialization ? course.Specializations.includes(specialization) : true) &&
                (courseType ? course.CourseType === courseType : true) &&
                (eligibility ? course.Eligibility === eligibility : true) &&
                (duration ? course.Duration === duration : true)
              ).map((course) => (
                <div key={course._id} style={styles.courseItem}>
                  <p><strong>Course Type:</strong> {course.CourseType}</p>
                  <p><strong>Specialization:</strong> {course.Specializations.join(', ')}</p>
                  <p><strong>Eligibility:</strong> {course.Eligibility}</p>
                  <p><strong>Duration:</strong> {course.Duration}</p>
                  <p><strong>Tuition Fee per Year:</strong> ₹{course.TuitionFeePerYear}</p>
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CourseFilter;
