import React, { useEffect, useState } from 'react';
import './DisplayPhoto.css';

// Import local images from the Assets directory
import image1 from '../Assets/1.jpg';
import image2 from '../Assets/2.jpg';
import image3 from '../Assets/3.jpg';
import image4 from '../Assets/4.jpg';
import image5 from '../Assets/5.jpeg';
import image6 from '../Assets/6.jpg';
import image7 from '../Assets/7.jpg';
import image8 from '../Assets/8.jpg';
import image9 from '../Assets/9.jpeg';
import image10 from '../Assets/10.jpg';
import image11 from '../Assets/11.jpeg';
import image12 from '../Assets/12.jpeg';
import image13 from '../Assets/13.jpg';
import image14 from '../Assets/14.jpeg';
import image15 from '../Assets/15.jpg';

const DisplayPhoto = () => {
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);

    // Static photo data with university events themes
    const photoData = [
        { id: 1, url: image1, title: 'CULTURAL FEST', description: 'VIBRANT CULTURAL FESTIVAL SHOWCASING ART AND DANCE.' },
        { id: 2, url: image2, title: 'TECH SYMPOSIUM', description: 'INNOVATIVE IDEAS PRESENTED BY BUDDING ENGINEERS AND TECH ENTHUSIASTS.' },
        { id: 3, url: image3, title: 'SPORTS DAY', description: 'ENERGETIC SPORTS COMPETITIONS AND TEAM SPIRIT ON DISPLAY.' },
        { id: 4, url: image4, title: 'ANNUAL ALUMNI MEET', description: 'A GATHERING OF ALUMNI SHARING THEIR SUCCESS STORIES AND EXPERIENCES.' },
        { id: 5, url: image5, title: 'RESEARCH EXPO', description: 'SSHOWCASING GROUNDBREAKING RESEARCH PROJECTS BY STUDENTS AND FACULTY.' },
        { id: 6, url: image6, title: 'ORIENTATION WEEK', description: 'WELCOMING NEW STUDENTS WITH EXCITING ACTIVITIES AND INTRODUCTION.' },
        { id: 7, url: image7, title: 'ENVIRONMENTAL AWARENESS PROGRAM', description: 'PROMOTING SUSTAINABILITY AND ECO-FRIENDLY PRACTICES ON CAMPUS.' },
        { id: 8, url: image8, title: 'LITERARY FEST', description: 'CELEBRATING LITERATURE WITH DISCUSSIONS, READINGS, AND WORKSHOPS.' },
        { id: 9, url: image9, title: 'HACKATHON', description: 'A 24-HOUR CODING MARATHON WHERE STUDENTS CREATE INNOVATIVE SOFTWARE SOLUTIONS.' },
        { id: 10, url: image10, title: 'MUSIC NIGHT', description: 'AN EVENING FILLED WITH SOULFUL PERFORMANCES BY STUDENTS AND GUEST ARTISTS.' },
        { id: 11, url: image11, title: 'WORKSHOPS AND SEMINARS', description: 'SKILL-BUILDING SESSIONS LED BY INDUSTRY EXPERTS AND PROFESSIONALS.' },
        { id: 12, url: image12, title: 'GRADUATION CEREMONY', description: 'A PROUD MOMENT FOR STUDENTS CELEBRATING THEIR ACHIEVEMENTS.' },
        { id: 13, url: image13, title: 'INTER-COLLEGE COMPETITIONS', description: 'EXCITING CONTESTS BRINGING TOGETHER STUDENTS FROM VARIOUS COLLEGES.' },
        { id: 14, url: image14, title: 'STUDENT EXCHANGE PROGRAM', description: 'CULTURAL EXCHANGE EXPERIENCES BETWEEN DIFFERENT UNIVERSITIES.' },
        { id: 15, url: image15, title: 'HEALTH AND WELLNESS CAMP', description: 'PROMOTING HEALTH AWARENESS AND FITNESS AMONG STUDENTS.' },
    ];

    useEffect(() => {
        // Simulate loading time
        const loadPhotos = () => {
            setPhotos(photoData);
            setLoading(false);
        };

        const timeoutId = setTimeout(loadPhotos, 1000); 

        return () => clearTimeout(timeoutId); 
    }, []);

    if (loading) {
        return <h2>Loading wallpapers...</h2>;
    }

    return (
        <div className="container">
            <h1>OUR EVENTS SHOWCASE</h1>
            <div className="gallery">
                {photos.map((photo) => (
                    <div className="card" key={photo.id}>
                        <img src={photo.url} alt={photo.title} />
                        <div className="text-container">
                            <h2>{photo.title}</h2>
                            <p>{photo.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DisplayPhoto;
