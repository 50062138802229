import React, { useState, useEffect } from "react";
import logo from '../Assets/logo.jpg';
import "./Header.css";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    // Check if token exists in localStorage (or sessionStorage)
    const token = localStorage.getItem('authToken'); 
    console.log('Token found:', token); // Add log for debugging
    
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []); 

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };

  const handleApply = () => {
    navigate('/Userform'); // Navigate to RegisterForm after alert
  };

  const handleLogin = () => {
    navigate('/AuthForm'); // Navigate to AuthForm after alert
  };

  const handleUserProfile = () => {
    navigate('/Userprofile'); 
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const closeMobileMenu = () => {
    setIsMobile(false);
    setIsDropdownOpen(false);
  };

  return (
    <header className="uni-navbar-header">
      <nav className="uni-navbar">
        <div className="uni-navbar-logo">
          <img src={logo} alt="University Logo" />
          <span>Campus Education</span>
        </div>
        <ul className={isMobile ? "uni-navbar-links-mobile" : "uni-navbar-links"}>
          <li><Link to="/" onClick={closeMobileMenu}>Home</Link></li>
          <li><Link to="/Courses" onClick={closeMobileMenu}>Explore More</Link></li>
          <li><Link to="/About" onClick={closeMobileMenu}>About</Link></li>
          <li><Link to="/UniversityList" onClick={closeMobileMenu}>University</Link></li>
          <li className="uni-dropdown">
            <button onClick={handleDropdownToggle} className="dropdown-toggle">
              Event
            </button>
            {isDropdownOpen && (
              <ul className="uni-dropdown-menu">
                <li><Link to="/DisplayPhoto" onClick={closeMobileMenu}>Photos</Link></li>
                <li><Link to="/DisplayVideos" onClick={closeMobileMenu}>Videos</Link></li>
              </ul>
            )}
          </li>
          <li><Link to="/Contact" onClick={closeMobileMenu}>Contact</Link></li>
        </ul>
        <div className="uni-navbar-search-lang-toggle">
          <input type="text" placeholder="Search..." />
          <button className="apply-button" onClick={handleApply}>Apply</button>
        </div>

        {/* Conditionally render the Login button based on token */}
        {!isLoggedIn && (
          <div className="uni-navbar-search-lang-toggle">
            <button className="apply-button" onClick={handleLogin}>Login</button>
          </div>
        )}

        {/* User Profile Icon */}
        <div className="uni-navbar-search-lang-toggle">
          <button className="profile-icon-button" onClick={handleUserProfile}>
            <i className="fas fa-user"></i>
          </button>
        </div>

        <div className="uni-navbar-toggle-btn" onClick={handleToggle} aria-label="Toggle Navigation">
          <span className={`bar ${isMobile ? "active" : ""}`}></span>
          <span className={`bar ${isMobile ? "active" : ""}`}></span>
          <span className={`bar ${isMobile ? "active" : ""}`}></span>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
