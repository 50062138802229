import React from 'react';
import './AcademicsPrograms.css'; // Import the CSS file
import images3 from '../Assets/images3.jpg';

const AcademicsPrograms = () => {
  return (
    <section className="program-section">
      <h2 className="section-title">Academics & Programs</h2>

      <div className="academic-overview">
        <h3>Our Academic Programs</h3>
        <ul>
          <li>
            <strong>Undergraduate (UG) Programs</strong><br />
            Choose from a variety of disciplines, including engineering, arts, commerce, science, and more. Our UG programs provide a strong academic foundation and practical skills to prepare students for the future.
          </li>
          <li>
            <strong>Postgraduate (PG) Programs</strong><br />
            Enhance your expertise with specialized PG programs in fields such as management, technology, healthcare, and more. These programs are designed to offer advanced knowledge and career-oriented training.
          </li>
          <li>
            <strong>Diploma Programs</strong><br />
            Get hands-on experience and industry-ready skills with our diploma programs in various professional fields. These short-term courses are perfect for individuals looking to gain practical knowledge quickly.
          </li>
          <li>
            <strong>Certificate Programs</strong><br />
            Upgrade your skills with our certificate programs, ideal for students and professionals seeking focused learning in specific areas. These courses are flexible and designed for continuous career development.
          </li>
        </ul>
      </div>

      <div className="programs-container">
  {/* Undergraduate Section */}
  <div className="program-box undergraduate">
    <h3>Undergraduate</h3>
    <div className="program-item undergrad-1">
      Bachelor of Science in Computer Science <span>→</span>
    </div>
    <div className="program-item undergrad-2">
      Bachelor of Business Administration (BBA) <span>→</span>
    </div>
    <div className="program-item undergrad-3">
      Bachelor of Arts in Psychology <span>→</span>
    </div>
    <div className="program-item undergrad-4">
      Bachelor of Commerce (B.Com) <span>→</span>
    </div>
    <img src={images3} alt="Undergraduate Programs" className="program-box-image" />
  </div>

  {/* Graduate Section */}
  <div className="program-box graduate">
    <h3>Graduate</h3>
    <div className="program-item grad-1">
      Master of Business Administration (MBA) <span>→</span>
    </div>
    <div className="program-item grad-2">
      Master of Science in Data Science <span>→</span>
    </div>
    <div className="program-item grad-3">
      Master of Arts in English <span>→</span>
    </div>
    <div className="program-item grad-4">
      Master of Computer Applications (MCA) <span>→</span>
    </div>
    <img src={images3} alt="Graduate Programs" className="program-box-image" />
  </div>

  {/* Lifelong Learning Section */}
  <div className="program-box lifelong-learning">
  <h3>Diploma Programs</h3>
  <div className="program-item diploma-1">
    Certificate in Digital Marketing <span>→</span>
  </div>
  <div className="program-item diploma-2">
    Diploma in Graphic Design <span>→</span>
  </div>
  <div className="program-item diploma-3">
    Advanced Certificate in Data Analytics <span>→</span>
  </div>
  <div className="program-item diploma-4">
    Workshop on Entrepreneurship Skills <span>→</span>
  </div>
  <img src={images3} alt="Diploma Programs" className="program-box-image" />
</div>
</div>
    </section>
  );
};

export default AcademicsPrograms;
