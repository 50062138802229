import React, { useState, useEffect } from 'react';
import './Courses.css';

const Courses = () => {
  const [selectedUniversity, setSelectedUniversity] = useState('');
  const [selectedCourseType, setSelectedCourseType] = useState('');
  const [selectedSpecialization, setSelectedSpecialization] = useState('');

  const [universitySearch, setUniversitySearch] = useState('');
  const [courseTypeSearch, setCourseTypeSearch] = useState('');
  const [specializationSearch, setSpecializationSearch] = useState('');

  const [isUniversityDropdownOpen, setUniversityDropdownOpen] = useState(false);
  const [isCourseDropdownOpen, setCourseDropdownOpen] = useState(false);
  const [isSpecializationDropdownOpen, setSpecializationDropdownOpen] = useState(false);

  // New state for API data and loading states
  const [apiData, setApiData] = useState({ Universities: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch universities data on component mount
  useEffect(() => {
    fetchUniversities();
  }, []);

  // API functions
  const fetchUniversities = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('https://campus-education-node-deploy-9eb11e988890.herokuapp.com/api/university/universities'); // Adjust the endpoint as needed
      if (!response.ok) throw new Error('Failed to fetch universities');
      const data = await response.json();
      setApiData({ Universities: data });
      setIsLoading(false);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  const handleUniversityChange = (university) => {
    setSelectedUniversity(university);
    setSelectedCourseType('');
    setSelectedSpecialization('');
    setUniversityDropdownOpen(false);
  };

  const handleCourseTypeChange = (courseType) => {
    setSelectedCourseType(courseType);
    setSelectedSpecialization('');
    setCourseDropdownOpen(false);
  };

  const handleSpecializationChange = (specialization) => {
    setSelectedSpecialization(specialization);
    setSpecializationDropdownOpen(false);
  };

  const filteredUniversities = (apiData.Universities || []).filter(uni =>
    uni.UniversityName.toLowerCase().includes(universitySearch.toLowerCase())
  );

  const selectedUniversityData = apiData.Universities?.find(
    (uni) => uni.UniversityName === selectedUniversity
  );

  const courses = selectedUniversityData?.Courses || [];

  const uniqueCourseTypes = Array.from(
    new Set(courses.map(course => course.CourseType.toLowerCase()))
  ).map(courseType => {
    return courses.find(course => course.CourseType.toLowerCase() === courseType);
  });

  const filteredCourses = (uniqueCourseTypes || []).filter(course =>
    course.CourseType.toLowerCase().includes(courseTypeSearch.toLowerCase())
  );

  const selectedCourseData = filteredCourses.find(
    (course) => course.CourseType === selectedCourseType
  );

  const filteredSpecializations = (selectedCourseData?.Specializations || []).filter(specialization =>
    specialization.toLowerCase().includes(specializationSearch.toLowerCase())
  );

  const getTuitionFee = (specialization) => {
    if (Array.isArray(selectedCourseData.TuitionFeePerYear)) {
      const index = selectedCourseData.Specializations.indexOf(specialization);
      return index >= 0 ? selectedCourseData.TuitionFeePerYear[index] : null;
    }
    return selectedCourseData.TuitionFeePerYear;
  };

  const getTotalFee = (specialization) => {
    if (Array.isArray(selectedCourseData.TotalFee)) {
      const index = selectedCourseData.Specializations.indexOf(specialization);
      return index >= 0 ? selectedCourseData.TotalFee[index] : null;
    }
    return selectedCourseData.TotalFee;
  };

  if (isLoading) {
    return <div className="course-container">Loading universities...</div>;
  }

  if (error) {
    return <div className="course-container">Error: {error}</div>;
  }

  return (
    <div className="course-container">
      <h1>Select a University</h1>

      {/* University Dropdown with Search */}
      <div className="course-custom-dropdown">
        <div
          className="course-dropdown-toggle"
          onClick={() => setUniversityDropdownOpen(!isUniversityDropdownOpen)}
        >
          {selectedUniversity || 'Select University'}
        </div>
        {isUniversityDropdownOpen && (
          <div className="course-dropdown-content">
            <input
              type="text"
              placeholder="Search University..."
              value={universitySearch}
              onChange={(e) => setUniversitySearch(e.target.value)}
              className="course-search-bar"
            />
            <ul className="course-options-list">
              {filteredUniversities.map((uni, index) => (
                <li
                  key={index}
                  onClick={() => handleUniversityChange(uni.UniversityName)}
                  className={selectedUniversity === uni.UniversityName ? 'course-option selected' : 'course-option'}
                >
                  {uni.UniversityName}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Course Type Dropdown with Search */}
      {selectedUniversity && (
        <>
          <h2>Select a Course Type</h2>
          <div className="course-custom-dropdown">
            <div
              className="course-dropdown-toggle"
              onClick={() => setCourseDropdownOpen(!isCourseDropdownOpen)}
            >
              {selectedCourseType || 'Select Course Type'}
            </div>
            {isCourseDropdownOpen && (
              <div className="course-dropdown-content">
                <input
                  type="text"
                  placeholder="Search Course Type..."
                  value={courseTypeSearch}
                  onChange={(e) => setCourseTypeSearch(e.target.value)}
                  className="course-search-bar"
                />
                <ul className="course-options-list">
                  {filteredCourses.map((course, index) => (
                    <li
                      key={index}
                      onClick={() => handleCourseTypeChange(course.CourseType)}
                      className={selectedCourseType === course.CourseType ? 'course-option selected' : 'course-option'}
                    >
                      {course.CourseType}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}

      {/* Specialization Dropdown with Search */}
      {selectedCourseType && selectedCourseData && (
        <>
          <h2>Select a Specialization</h2>
          <div className="course-custom-dropdown">
            <div
              className="course-dropdown-toggle"
              onClick={() => setSpecializationDropdownOpen(!isSpecializationDropdownOpen)}
            >
              {selectedSpecialization || 'Select Specialization'}
            </div>
            {isSpecializationDropdownOpen && (
              <div className="course-dropdown-content">
                <input
                  type="text"
                  placeholder="Search Specialization..."
                  value={specializationSearch}
                  onChange={(e) => setSpecializationSearch(e.target.value)}
                  className="course-search-bar"
                />
                <ul className="course-options-list">
                  {filteredSpecializations.map((specialization, index) => (
                    <li
                      key={index}
                      onClick={() => handleSpecializationChange(specialization)}
                      className={selectedSpecialization === specialization ? 'course-option selected' : 'course-option'}
                    >
                      {specialization}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}

      {/* Card Display */}
      {selectedSpecialization && (
        <div className="course-card">
          <h3>{selectedUniversity}</h3>
          <h4>Course Type: {selectedCourseType}</h4>
          <h4>Specialization: {selectedSpecialization}</h4>
          <hr />
          <p><strong>Eligibility:</strong> {selectedCourseData.Eligibility}</p>
          <p><strong>Duration:</strong> {selectedCourseData.Duration}</p>
          <p><strong>Fee Per Year:</strong> ₹{getTuitionFee(selectedSpecialization)}</p>
          <p><strong>Total Fee:</strong> ₹{getTotalFee(selectedSpecialization)}</p>
          <p><strong>AICTE Approved:</strong> Yes</p>
        </div>
      )}
    </div>
  );
};

export default Courses;