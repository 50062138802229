import React, { useRef } from 'react';
import './Feedback.css'; // Import the CSS file for styling
import user from '../Assets/user.jpg';

export const Feedback = () => {
  const feedbackData = [
    {
      id: 1,
      name: 'Ananya Sharma',
      course: 'BBA',
      imgSrc: user,
      feedback:
        'Campus Education made my admission process seamless and stress-free. The counselors guided me through every step, helping me choose the right university and program based on my goals. Now, I’m studying at a top university and enjoying a great campus life. I couldn’t have done it without their support!',
      rating: 5,
    },
    {
      id: 2,
      name: 'Rohan Mehta',
      course: 'MBA',
      imgSrc: user,
      feedback:
        'Thanks to Campus Education, I was able to secure admission into a prestigious MBA program. They provided detailed guidance, helped with documentation, and made the process so easy. The university environment has been amazing, with excellent facilities and opportunities for personal growth. Highly recommend their services!',
      rating: 5,
    },
    {
      id: 3,
      name: 'Priya Verma',
      course: 'Diploma in Physiotherapy',
      imgSrc: user,
      feedback:
        'I had no idea how to navigate the admission process, but Campus Education made it all so simple. From helping me choose the right course to assisting with all the formalities, they were always there. Now, I’m studying in a top institution with great hands-on training, and I couldn’t be happier with my decision.',
      rating: 5,
    },
    {
      id: 4,
      name: 'Akash Gupta',
      course: 'B.Tech in Computer Science',
      imgSrc: user,
      feedback:
        'The team at Campus Education made the entire admission process so easy and hassle-free. They helped me get into a top engineering program and guided me at every step. The support didn’t stop after admission—Campus Education was there for me even after I started my studies. I’m grateful for their help!',
      rating: 5,
    },
    {
      id: 5,
      name: 'Meenakshi Iyer',
      course: 'MA in English Literature',
      imgSrc: user,
      feedback:
        'I was confused about which course to pursue for my postgraduate studies, but Campus Education provided excellent counseling. They understood my interests and helped me select the right university. Now, I’m studying what I love in a great environment. Thank you for making this journey so smooth!',
      rating: 5,
    },
    {
      id: 6,
      name: 'Ravi Patel',
      course: 'Diploma in Hotel Management',
      imgSrc: user,
      feedback:
        'Campus Education was a lifesaver! I wasn’t sure where to apply, but their team made everything so clear. They provided a list of options that fit my career goals, and today I’m enrolled in a top hospitality program. The campus life and education have exceeded my expectations!',
      rating: 5,
    },
    {
      id: 7,
      name: 'Shruti Deshmukh',
      course: 'B.Sc. in Biotechnology',
      imgSrc: user,
      feedback:
        'I had always dreamed of studying biotechnology, but I didn’t know how to get into the right university. Campus Education helped me find the perfect program. The counselors were so knowledgeable and patient. Now, I’m at one of the best universities in the country, and I’m loving it!',
      rating: 5,
    },
    {
      id: 8,
      name: 'Manish Kumar',
      course: 'MBA in Finance',
      imgSrc: user,
      feedback:
        'Campus Education is truly a one-stop solution for admissions. Their team provided step-by-step guidance, from shortlisting universities to ensuring I had all the required documents. The entire process was smooth and efficient. I am now pursuing an MBA in finance, and the university is fantastic!',
      rating: 5,
    },
    {
      id: 9,
      name: 'Kavya Singh',
      course: 'BA in Journalism and Mass Communication',
      imgSrc: user,
      feedback:
        'The counseling team at Campus Education really understands the needs of students. They took the time to understand my passion for journalism and suggested the best universities for me. I’m now in a program that’s both academically challenging and creatively fulfilling. Thank you!',
      rating: 5,
    },
    {
      id: 10,
      name: 'Abhishek Jain',
      course: 'M.Sc. in Data Science',
      imgSrc: user,
      feedback:
        'I was struggling to find a good data science program, but Campus Education made it so easy. Their expert guidance and support helped me choose the perfect university. The course and faculty have been incredible, and I’m learning so much. Campus Education made my dream come true!',
      rating: 5,
    },
    {
      id: 11,
      name: 'Nidhi Rao',
      course: 'Diploma in Fashion Design',
      imgSrc: user,
      feedback:
        'I’m really thankful to Campus Education for helping me find the right course in fashion design. I was unsure of where to apply, but their team provided excellent advice and support. Now, I’m studying at a top fashion institute, and I’m so excited about my future in the industry!',
      rating: 5,
    },
    {
      id: 12,
      name: 'Vikas Sharma',
      course: 'B.Com. in Accounting',
      imgSrc: user,
      feedback:
        'Campus Education’s support was invaluable. They walked me through the entire admission process, from selecting universities to submitting my application. I’m now studying commerce at a leading university, and the experience has been amazing. Highly recommend their services!',
      rating: 5,
    },
    {
      id: 13,
      name: 'Aishwarya Verma',
      course: 'LLB',
      imgSrc: user,
      feedback:
        'The team at Campus Education helped me choose the perfect law school. They were patient, knowledgeable, and supportive throughout the entire process. Now, I’m pursuing my law degree at one of the best universities, and I couldn’t be happier. Thank you for everything!',
      rating: 5,
    },
    {
      id: 14,
      name: 'Sandeep Raj',
      course: 'B.Sc. in Nursing',
      imgSrc: user,
      feedback:
        'I always wanted to pursue nursing, but the admission process seemed so complicated. Campus Education simplified everything for me. They provided great guidance and helped me get into a well-reputed nursing program. I’m now on the path to my dream career!',
      rating: 5,
    },
  ];

  const scrollRef = useRef(null); 

  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = scrollRef.current.clientWidth / 3; 
      scrollRef.current.scrollBy({
        left: direction === 'right' ? scrollAmount : -scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  return (
    <section className="feedback-section">
      <h2 className="feedback-title">Our Students Feedback</h2>
      <p className="feedback-subtitle">You’ll find something to spark your curiosity and enhance</p>

      <div className="feedback-cards" ref={scrollRef}>
        {feedbackData.map((item) => (
          <div key={item.id} className="feedback-card">
            <div className="feedback-stars">
              {'★'.repeat(item.rating)}
            </div>
            <p className="feedback-text">{item.feedback}</p>
            <div className="feedback-person">
              <img src={item.imgSrc} alt={item.name} className="person-image" />
              <div>
                <h4 className="person-name">{item.name}</h4>
                <p className="person-role">{item.role}</p>
              </div>
              <div className="feedback-quote">❞</div>
            </div>
          </div>
        ))}
      </div>

      {/* Navigation buttons */}
      <button className="scroll-button left" onClick={() => scroll('left')}>❮</button>
      <button className="scroll-button right" onClick={() => scroll('right')}>❯</button>
    </section>
  );
};