// TestimonialCard.jsx
import React from 'react';
import './testpro.css';
import middle1 from '../Assets/middle1.jpg'
import logo from '../Assets/logo.jpg'

const TestimonialCard = () => {
  return (
    <div className="testimonial-container">
      <div className="testimonial-card">
        <div className="testimonial-image-container">
          <img
            src={middle1}
            alt="Senior Director portrait"
            className="testimonial-image"
          />
        </div>
        <div className="testimonial-content">
          <div className="testimonial-logo">
            <img
              src={logo}
              alt="MailChimp logo"
              className="logo"
            />
          </div>
          <blockquote className="testimonial-quote">
            "As the Director, I am genuinely grateful for the exceptional dedication and meticulous attention to detail demonstrated by our Admission Processing team. Their commitment ensures a smooth experience for every applicant, and I have no doubt that many will return to us with trust and confidence in the future."
          </blockquote>  
          <div className="testimonial-author">
            <h3 className="author-name">Mr. Sunil Pratap</h3>
            <p className="author-title">Senior Director</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;

